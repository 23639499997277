import { createAction, createReducer } from '@reduxjs/toolkit';
import AuthService from '../Services/AuthService';
import TokenService from '../Services/TokenService';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';

export const authSetIsAuthenticated = createAction('auth/set');

export const authSignIn = createAction('auth/sign-in', (idToken, accessToken) => ({
  payload: AuthService.signInAmazon(idToken, accessToken),
}));

export const authGetTokens = createAction('auth/get-tokens', (code) => ({
  payload: AuthService.getTokens(code),
}));

export const authSignOut = createAction('auth/sing-out', () => ({
  payload: TokenService.setToken(null),
}));

const initState = {
  isAuthenticated: false,
  tokens: getDefaultState(),
};

export default createReducer(initState, {
  [authSetIsAuthenticated]: (state, action) => ({ ...state, isAuthenticated: action.payload }),
  ...getDefaultHandler(authGetTokens, 'tokens'),
});
