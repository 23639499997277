import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import ConfigService from '../Services/ConfigService';

export const fetchConfigs = createAction('config/get', () => ({
  payload: ConfigService.getList(),
}));

export const updateConfig = createAction('config/update', (data) => ({
  payload: ConfigService.updateConfig(data),
}));

const initState = {
  data: getDefaultState(),
  update: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchConfigs, 'data'),
  ...getDefaultHandler(updateConfig, 'update'),
});
