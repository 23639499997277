import { createAction } from '@reduxjs/toolkit';

export const getDefaultState = () => ({
  error: null,
  isLoading: false,
  payload: null,
});

export const getDefaultHandler = (action, path = '') => ({
  [action + '_pending']: (state) => {
    if (path) {
      state[path].isLoading = true;
    } else {
      state.isLoading = true;
    }
  },
  [action + '_rejected']: (state, action) => {
    console.log('...payloadpayloadpayload.......', action.payload);
    if (path) {
      state[path].isLoading = false;
      state[path].error = action.payload;
      state[path].payload = null;
    } else {
      state.isLoading = false;
      state.error = action.payload;
      state.payload = null;
    }
  },
  [action + '_fulfilled']: (state, action) => {
    if (path) {
      state[path].isLoading = false;
      state[path].payload = action.payload;
      state[path].error = null;
    } else {
      state.isLoading = false;
      state.payload = action.payload;
      state.error = null;
    }
  },
  [action + '_reset']: (state) => {
    if (path) {
      state[path] = getDefaultState();
    } else {
      state = getDefaultState();
    }
  },
});

export const getResetAction = (action) => (createAction(action + '_reset'));

export const middleware = store => dispatch => action => {
  if (action.payload instanceof Promise) {

    dispatch({
      type: action.type + '_pending',
    });

    console.log('..........', action.payload);
    return action.payload.then(data => {
      dispatch({
        type: action.type + '_fulfilled',
        payload: data,
      });
      return data;
    }).catch(err => {
      console.log('..........', action.type + '_rejected');
      dispatch({
        type: action.type + '_rejected',
        payload: err,
      });
      throw err;
    });
  }

  dispatch(action);
};
