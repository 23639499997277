
export function getDomainUrl() {
  return new URL(window.location.href).origin
}

export function isPaxful() {
  return new URL(window.location.href).origin.includes('paxful')
}

export function getRedirectUrl() {
  return getDomainUrl() + '/redirect'
}

export function getTitle() {
  if (isPaxful()) {
    return 'Paxful'
  } else {
    return 'Noones'
  }
}
