import ApiService from "./ApiService";


export default {
    getPricingOffers ({ filters, orderPath, ...params }) {
        const updatedParams = { ...params };

        if (filters && Object.keys(filters).length) {
            for (let key in filters) {
                updatedParams[key] = Array.isArray(filters[key]) ? filters[key].join(',') : filters[key];
            }
        }

        if (orderPath) {
            updatedParams.order = orderPath.replace('end', '');
        }

        return ApiService.get('/pricing_offers', updatedParams)
    },
    editPricingOffer (params) {
        return ApiService.put('/pricing_offer', params)
    },
    editPricingOffers (params) {
        return ApiService.put('/pricing_offers', params)
    },
    createPricingOffer (params) {
        return ApiService.post(`/pricing_offer`, params)
    },
    deletePricingOffer(id){
        return ApiService.delete(`/pricing_offer/${id}`)
    }
}