import React from 'react';
import { Navigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import Layout from '../Layouts';

function RedirectGuestToSignInPage() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);
  
  return isAuthenticated ? <Layout /> : <Navigate to={`/sign-in`} />;
}

export default RedirectGuestToSignInPage;
