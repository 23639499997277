import React, { useEffect, useState } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import TokenService from '../../Services/TokenService';
import {getRedirectUrl} from "../../Utils/envDependent";


const {
  REACT_APP_AUTH_CLIENT_ID: CLIENT_ID,
  REACT_APP_AUTH_URL: AUTH_URL,
  REACT_APP_AUTH_RESPONSE_TYPE: RESPONSE_TYPE,
  REACT_APP_AUTH_SCOPE: SCOPE,
} = process.env;

const url = `${AUTH_URL}/logout?client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&redirect_uri=${getRedirectUrl()}`;

function Logout({ className = '' }) {
  const [sha, setSha] = useState(null);

  useEffect(() => {
      TokenService.generateAndSaveSha256().then(data => {
        setSha(data);
      });
    },
    [],
  );

  return (<a href={sha && (url + `&code_challenge_method=S256&code_challenge=${sha}`)}
  >
    <LogoutOutlined className={className} />
  </a>);
}

export default React.memo(Logout);
