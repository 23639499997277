import TokenService from './TokenService';
import ApiService from './ApiService';
import {getRedirectUrl} from "../Utils/envDependent";

const {
  REACT_APP_AUTH_CLIENT_ID: CLIENT_ID,
  REACT_APP_AUTH_URL: AUTH_URL,
} = process.env;

export default {
  signInAmazon(idToken, accessToken) {
    return new Promise(resolve => {
      TokenService.setToken(idToken, accessToken);
      resolve();
    });
  },
  getTokens(code) {
    const url = AUTH_URL + '/token';

    const data = {
      code,
      grant_type: 'authorization_code',
      client_id: CLIENT_ID,
      redirect_uri: getRedirectUrl(),
      code_verifier: TokenService.getChallenge(),
    };

    return ApiService.postToCognito(url, data);
  }
};
