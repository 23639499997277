import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Routes from '../Routes';
import './styles.css';
import { getTitle } from '../../Utils/envDependent';

class App extends React.Component {
  render() {
    return <div className="App">
      <Routes />
    </div>;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

// Wrap the connected component with a new component that sets the document title
function AppWithDynamicTitle() {
  useEffect(() => {
    document.title = getTitle();
  }, []);

  return <ConnectedApp />;
}

export default AppWithDynamicTitle;
