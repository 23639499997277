import React, {useCallback, useEffect, useState} from 'react';
import {Button, message, Popconfirm} from 'antd';
import b_ from 'b_';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import './styles.scss';
import TableSettings from "./TableSettings";
import useStorageState from "../../../Utils/useStorageState";
import ResizeTable from "../../../Components/ResizeableTitle";
import UpdatePricingOffer from "../../../Components/UpdatePricingOffer";
import {deletePricingOffer, editPricingOffer} from "../../../Reducers/pricingOffers";
import PrintPricingOfferStatus from "../../../Components/Prints/PrintPricingOfferStatus";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {Switch} from 'antd';
import PrintPricingOfferAlert from "../../../Components/Prints/PrintPricingOfferAlert";
import {fetchAux} from "../../../Reducers/aux";


const b = b_.lock('AccountsTable');

function PricingOffersTable({
                              pricingOffers,
                              pagination,
                              onChange,
                              isLoading,
                              afterUpdate,
                              selected,
                              setSelected,
                              sorter,
                              onFilter
                            }) {

  const dispatch = useDispatch();

    const aux = useSelector((state) => state.aux.data.payload, shallowEqual);

    const loadings = useSelector(state => ({
        pricingOffers: state.pricingOffers.editPricingOffer.isLoading,
    }), shallowEqual);

    useEffect(() => {
        if (!aux) {
            dispatch(fetchAux());
        }
    }, []);

    const updatePricingOffers = useCallback((params) => {
    dispatch(editPricingOffer(params)).then((e) => {
      try {
        message.success('Offer update completed.');
        afterUpdate()
      } catch (e) {
        message.error('Offer update failed');
        afterUpdate()
      }
    })
  }, [dispatch, afterUpdate]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('columnsPricingOffers', {
    checkedList: [],
    indeterminate: false,
    checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < columns.length,
      checkAll: checkedList.length === columns.length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeletePricingOffer = useCallback((id) => {
    dispatch(deletePricingOffer(id)).then(() => {
      message.success('Pricing offer deleted!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t delete pricing offer');
    })
  }, [dispatch]);


  const GetColumnTable = (sorter) => ([
    {
      dataIndex: "id",
      title: "ID",
      align: "left",
      defaultView: true,
      width: 50,
      sorter,
      sortOrder: sorter.field === 'id' ? sorter.order : null,
      render: id => <span>{id}</span>,
    },
    {
      dataIndex: "hash",
      title: "Hash",
      align: "left",
      defaultView: true,
      width: 50,
      sorter,
      sortOrder: sorter.field === 'hash' ? sorter.order : null,
      render: (hash, rec) => (<UpdatePricingOffer
        type="text"
        updatePricingOffers={updatePricingOffers}
        value={hash}
        title={"hash"}
        rec={rec}/>)
    },
    {
      dataIndex: "trustedHash",
      title: "Trusted Hash",
      align: "left",
      defaultView: true,
      width: 50,
      sorter,
      sortOrder: sorter.field === 'trustedHash' ? sorter.order : null,
      render: (trustedHash, rec) => (<UpdatePricingOffer
        type="text"
        updatePricingOffers={updatePricingOffers}
        value={trustedHash}
        title={"trustedHash"}
        rec={rec}/>)
    },
    {
      dataIndex: "paxfulClientName",
      title: "Paxful Client",
      align: "left",
      width: 50,
      sorter,
      sortOrder: sorter.field === 'paxfulClientName' ? sorter.order : null,
      render: (paxfulClientName, rec) => (<UpdatePricingOffer
        type="paxful-client"
        updatePricingOffers={updatePricingOffers}
        value={paxfulClientName}
        title={"paxfulClientName"}
        rec={rec}/>),
      filters: aux && aux.paxfulClientNames.map(name => {
          return {text: name, value: name}
      }),
      onFilter: (value, record) => record.paxfulClientName.indexOf(value) === 0,
    },
    {
      dataIndex: "paymentMethod",
      title: "Payment Method",
      align: "left",
      width: 50,
      sorter,
      sortOrder: sorter.field === 'paymentMethod' ? sorter.order : null,
      render: (paymentMethod, rec) => <span>{paymentMethod}</span>,
      filters: aux && aux.paymentMethods.map(method => {
        return {text: method, value: method}
      }),
      onFilter: (value, record) => record.paymentMethod.indexOf(value) === 0,
    },
    {
      dataIndex: "currency",
      title: "Currency",
      align: "left",
      width: 50,
      sorter,
      sortOrder: sorter.field === 'currency' ? sorter.order : null,
      render: (currency, rec) => <span>{currency}</span>,
      filters: aux && aux.currencies.map(curr => {
        return {text: curr, value: curr}
      }).sort((a, b) => a.text.localeCompare(b.text)),
      onFilter: (value, record) => record.currency.indexOf(value) === 0,
    },
    {
      dataIndex: "status",
      title: "Status",
      align: "center",
      width: 50,
      sorter,
      sortOrder: sorter.field === 'status' ? sorter.order : null,
      render: (status, rec) => (<PrintPricingOfferStatus
        handleSave={updatePricingOffers}
        status={status}
        field={"status"}
        rec={rec}/>)
    },
    {
      dataIndex: "alert",
      title: "Alert",
      align: "center",
      width: 50,
      sorter,
      sortOrder: sorter.field === 'alert' ? sorter.order : null,
      render: (alert, rec) => (<PrintPricingOfferAlert alert={alert}/>)
    },
    {
      dataIndex: "price",
      title: "Price/Margin",
      align: "right",
      defaultView: true,
      width: 150,
      render: (price, rec) => <span>{price || rec.margin}</span>
    },
    {
      dataIndex: "paxfulPrice",
      title: "Paxful Price",
      align: "right",
      defaultView: true,
      width: 150,
      sorter,
      sortOrder: sorter.field === 'paxfulPrice' ? sorter.order : null,
      render: (price, rec) => <span>{price}</span>
    },
    {
      dataIndex: "paxfulMargin",
      title: "Paxful Margin",
      align: "right",
      defaultView: true,
      width: 150,
      sorter,
      sortOrder: sorter.field === 'paxfulMargin' ? sorter.order : null,
      render: (margin, rec) => <span>{margin}</span>
    },
    {
      dataIndex: "fixedPrice",
      title: "Fixed Price",
      align: "center",
      width: 50,
      sorter,
      sortOrder: sorter.field === 'fixedPrice' ? sorter.order : null,
      render: (fixedPrice, rec) => (<Switch
          size="small"
          checked={fixedPrice}
          checkedChildren={<CheckOutlined/>}
          unCheckedChildren={<CloseOutlined/>}
          onClick={(e) => {
            const params = {
              id: rec.id,
              version: rec.version,
              fixedPrice: !fixedPrice
            }
            updatePricingOffers(params)
          }}/>
      )
    },
    {
      dataIndex: "minMargin",
      title: "Margin",
      align: "right",
      width: 50,
      sorter,
      sortOrder: sorter.field === 'minMargin' ? sorter.order : null,
      render: (minMargin, rec) => (<UpdatePricingOffer
        type="number"
        updatePricingOffers={updatePricingOffers}
        value={minMargin}
        title={"minMargin"}
        rec={rec}/>)
    },
    {
      dataIndex: "rate",
      title: "Rate",
      align: "right",
      width: 50,
      sorter,
      sortOrder: sorter.field === 'rate' ? sorter.order : null,
      render: (rate, rec) => (<UpdatePricingOffer
        type="rate"
        updatePricingOffers={updatePricingOffers}
        value={rate}
        title={"rate"}
        rec={rec}/>)
    },
    // {
    //   dataIndex: "maxMargin",
    //   title: "Max Margin",
    //   align: "right",
    //   width: 50,
    //   sorter,
    //   sortOrder: sorter.field === 'maxMargin' ? sorter.order : null,
    //   render: (maxMargin, rec) => (<UpdatePricingOffer
    //     type="number"
    //     updatePricingOffers={updatePricingOffers}
    //     value={maxMargin}
    //     title={"maxMargin"}
    //     rec={rec}/>)
    // },
    {
      dataIndex: "id",
      title: "Actions",
      align: "center",
      width: "204px",
      key: "group",
      render: (id, rec) => <div className={b('actions')}>
        <Popconfirm title={"Do you really want to delete offer?"} onConfirm={() => onDeletePricingOffer(id)}>
          <Button type={"link"} style={{padding: 0, height: 21}}>Delete</Button>
        </Popconfirm>
      </div>
    }
  ]);

  const [columns, setColumns] = useState(GetColumnTable(sorter));

  useEffect(() => {
    if (AllCheckbox.checkAll) {
      setAllCheckbox({
        checkedList: GetColumnTable(sorter).map(e => e.title),
        indeterminate: false,
        checkAll: true,
      })
    }
  }, [AllCheckbox.checkAll])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
      checkedList: (e.target.checked && columns.map(e => e.title)) || [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setColumns(GetColumnTable(sorter))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorter, aux])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

  return <ResizeTable bordered
                      className={b()}
                      scroll={{x: 'max-content'}}
                      dataSource={pricingOffers}
                      onChange={onChange}
                      columns={columnsFilter}
                      pagination={pagination}
                      loading={loader}
                      onFilter={onFilter}
                      size="small"
                      rowClassName={() => b('lock')}
                      rowKey="id"
                      style={{wordBreak: "break-word", wordWrap: "break-word"}}
                      title={() => <TableSettings
                        onChangeCheckAll={onChangeCheckAll}
                        allChecks={AllCheckbox}
                        Checkboxes={AllCheckbox.checkedList}
                        TableColumn={() => GetColumnTable(sorter)}
                        setCheckbox={onChangeCheckList}
                      />}
                      rowSelection={{
                        selectedRowKeys: selected,
                        onChange: setSelected,
                      }}
  >
  </ResizeTable>;
}

export default PricingOffersTable;
