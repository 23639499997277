import React from 'react';
import PropTypes from 'prop-types';
import {Popover, Tag} from 'antd';
import "./styles.scss"

const STATE_TO_COLOR = {
  Enabled: 'green',
  Disabled: 'gray'
};

function PrintPricingOfferStatus({className, status, handleSave, rec, field, editable = true}) {
  const statuses = ["Disabled", "Enabled"]
  const handleSaveUp = (value) => {
    const params = {
      id: rec.id,
      version: rec.version,
    }
    params[field] = value
    handleSave(params);
  }

  return <Popover content={`Change Status on the ${statuses.filter(e => e !== status)[0]}`}>
    <Tag style={editable ? {cursor: "pointer"} : {}}
         onClick={() => {
           if (editable) handleSaveUp(...statuses.filter(e => e !== status))
         }}
         className={[className, status]} color={STATE_TO_COLOR[status]}>{status}</Tag>
  </Popover>
}

PrintPricingOfferStatus.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(STATE_TO_COLOR)).isRequired,
  rec: PropTypes.object,
  handleSave: PropTypes.func,
};

PrintPricingOfferStatus.defaultProps = {
  className: '',
  rec: {},
};

export default PrintPricingOfferStatus;
