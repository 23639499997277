import {createAction, createReducer} from "@reduxjs/toolkit";
import {getDefaultHandler, getDefaultState} from "../Utils/newReducerTools";
import PricingOfferService from "../Services/PricingOfferService";


export const getPricingOffers = createAction('/pricing-offers', (params) => ({
    payload: PricingOfferService.getPricingOffers(params)
}))
export const editPricingOffer = createAction('/pricing-offer-edit', (params) => ({
    payload: PricingOfferService.editPricingOffer(params)
}))

export const editPricingOffers = createAction('/pricing-offers-edit', (params) => ({
    payload: PricingOfferService.editPricingOffers(params)
}))

export const createPricingOffer = createAction('/pricing-offers-create', (params) => ({
    payload: PricingOfferService.createPricingOffer(params)
}))

export const deletePricingOffer = createAction('/pricing-offers-delete', (id) => ({
    payload: PricingOfferService.deletePricingOffer(id)
}))

const initialState = {
    pricingOffers: getDefaultState(),
    editPricingOffer: getDefaultState(),
    editPricingOffers: getDefaultState(),
    createPricingOffer: getDefaultState(),
    deletePricingOffer: getDefaultState()
}

export default  createReducer(initialState, {
    ...getDefaultHandler(getPricingOffers, 'pricingOffers'),
    ...getDefaultHandler(editPricingOffer, 'editPricingOffer'),
    ...getDefaultHandler(editPricingOffers, 'editPricingOffers'),
    ...getDefaultHandler(createPricingOffer, 'createPricingOffer'),
    ...getDefaultHandler(deletePricingOffer, 'deletePricingOffer')
})
