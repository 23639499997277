import React, { useCallback } from 'react';
import { Col, Input, message, Row } from 'antd';
import b_ from 'b_';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateConfig } from '../../../Reducers/config';
import './styles.scss';
import EditableField from '../../../Components/EditableField';

const b = b_.lock('ConfigItem');

function ConfigItem({ item, fetch }) {
  const dispatch = useDispatch();

  const handleSave = useCallback(
    (value) => {
      dispatch(updateConfig({ name: item.name, value })).then(() => {
        message.success(`${item.name} has been updated!`);
        fetch();
      });
    },
    [dispatch, item.name, fetch],
  );

  const isLoading = useSelector(
    (state) => state.config.update.isLoading,
    shallowEqual,
  );

  return (
    <Row className={b()}>
      <Col span={6} className={b('title')}>
        {item.name}
      </Col>
      <Col span={18}>
        <EditableField
          handleSave={handleSave}
          withTitle
          title={item.name}
          initialValue={item.value}
          iconClassName={b('icon')}
          isLoading={isLoading}
          changeBlock={(props) => (
            <Input.TextArea
              {...props}
              onChange={(e) => props.onChange(e.target.value)}
            />
          )}
        >
          {item.value}
        </EditableField>
      </Col>
    </Row>
  );
}

export default ConfigItem;
