import { createAction, createReducer } from '@reduxjs/toolkit';
import { getDefaultHandler, getDefaultState } from '../Utils/newReducerTools';
import AuxService from "../Services/AuxService";

export const fetchAux = createAction('aux/get', () => ({
  payload: AuxService.getAux(),
}));

const initState = {
  data: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchAux, 'data'),
});
