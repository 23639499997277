import React from 'react';
import PropTypes from 'prop-types';
import {Popover, Tag} from 'antd';
import "./styles.scss"

const TAG_COLOR = {
  OK: 'green',
  Error: 'red'
};

function PrintPricingOfferAlert({className, alert}) {
  const tag = alert === null ? "OK" : "Error"

  return <Popover content={`${alert ?? 'OK'}`}>
    <Tag className={[className, tag]} color={TAG_COLOR[tag]}>{tag}</Tag>
  </Popover>
}

PrintPricingOfferAlert.propTypes = {
  className: PropTypes.string,
  alert: PropTypes.string,
};

PrintPricingOfferAlert.defaultProps = {
  className: ''
};

export default PrintPricingOfferAlert;
