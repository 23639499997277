import {combineReducers} from '@reduxjs/toolkit';
import auth from './auth';
import config from './config';
import aux from './aux';
import pricingOffers from "./pricingOffers";

export default combineReducers({
  auth,
  config,
  aux,
  pricingOffers
});
