import React from 'react';
import b_ from 'b_';
import './styles.scss';

const b = b_.lock('AuthLayout');

function AuthLayout({ children, className = '' }) {
  return <div className={[b(), className].join(' ')}>
    <div className={b('inner')}>
      {children}
    </div>
  </div>;
}


export default AuthLayout;
