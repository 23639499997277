import React from 'react';
import { Button, Checkbox, Col, Row, Typography } from 'antd';
import useBoolean from "../../../../Utils/useBoolean";
import  '../styles.scss'
const CheckboxGroup = Checkbox.Group;
const { Title } = Typography;

function TableSettings({ allChecks, onChangeCheckAll, setCheckbox, Checkboxes, TableColumn }) {
    const { value: isOpen, toggleValue: toggleOpen } = useBoolean(false);
    let nameCheckList = []
    let nameField = ""

    TableColumn().filter((e) => {
        for(nameField in e) {
            if(nameField === "title") {
                nameCheckList.push( e[nameField])
            }
        }
    })

    return (
        <>
            {!isOpen && (
                <Button type="link" onClick={toggleOpen}>
                    Open table settings
                </Button>
            )}
            {isOpen && (
                <>
                    <Title level={2}>
                        Settings
                        <Button type="link" onClick={toggleOpen}>
                            Close table settings
                        </Button>
                    </Title>
                    <Title level={3}>Select Columns</Title>
                    <Row className="mb">
                        <Checkbox
                            indeterminate={allChecks.indeterminate}
                            onChange={ onChangeCheckAll}
                            checked={allChecks.checkAll}
                        >
                            Select All
                        </Checkbox>
                    </Row>
                    <Row>
                        <CheckboxGroup
                            value={Checkboxes}
                            onChange={(e)=> setCheckbox(e)}
                            className="checksBoxGroup"
                        >
                            {nameCheckList.map((item, key) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Col
                                    key={key}
                                    className="mb-small"
                                    style={{width:20, height: 20}}
                                    span={8}
                                >
                                    <Checkbox value={item}>{item}</Checkbox>
                                </Col>
                            ))}
                        </CheckboxGroup>
                    </Row>
                </>
            )}
        </>
    );
}

export default TableSettings;
