import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { parseQueryString, prepareQueryString } from '../Services/ApiService';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const EMPTY_FILTERS = {};

export default function (selector, fetchAction) {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    payload,
    isLoading,
    error,
  } = useSelector(selector, shallowEqual);

  const { list, total } = useMemo(() => ({
    list: (payload && payload.list) || [],
    total: (payload && payload.total) || 0,
  }), [payload]);

  const { archive, archiveTotal } = useMemo(() => ({
      archive: (payload && payload.list) || [],
      archiveTotal: (payload && payload.total) || 0,
  }), [payload]);

    const { data } = useMemo(() => ({
      data: (payload && payload) || [],
    }), [payload]);

  const params = useMemo(
    () => ({ limit: 50, offset: 0, ...parseQueryString(location.search) }),
    [location.search],
  );

  const fetch = useCallback(() => {
      dispatch(fetchAction({ limit: 50, offset: 0, ...parseQueryString(location.search) }));
  }, [params, dispatch, fetchAction, location.search]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const onChange = useCallback(({ pageSize, current }, newTableFilters, { field, order }) => {
    const limit = pageSize;
    let offset = (current - 1) * pageSize;

    if (params.offset === offset) {
      offset = 0;
    }

    const orderBy = (field && order) ? field : null;
    const orderPath = (orderBy && order) || null;

    const curFilters = { ...filters, ...newTableFilters };
    const newFilters = Object.keys(curFilters)
      .filter(key => curFilters[key] !== null)
      .reduce((acc, cur) => ({ ...acc, [cur]: curFilters[cur] }), {});;

    navigate({
      pathname: location.pathname,
      search: prepareQueryString({
        ...params,
        limit,
        filters: newFilters,
        offset,
        orderBy,
        orderPath,
      }),
    });
  }, [location.pathname, params, navigate]);

  const onFilter = useCallback((newTableFilters) => {
    const curFilters = { ...filters, ...newTableFilters };
    const newFilters = Object.keys(curFilters)
      .filter(key => curFilters[key] !== null)
      .reduce((acc, cur) => ({ ...acc, [cur]: curFilters[cur] }), {});

    navigate({
      pathname: location.pathname,
      search: prepareQueryString({
        ...params,
        offset: 0,
        filters: newFilters,
      }),
    });
  }, [location.pathname, params, navigate]);

  const onSearch = useCallback((query = undefined) => {
    if (!query.trim()) query = undefined;

    navigate({
      pathname: location.pathname,
      search: prepareQueryString({
        ...params,
        offset: 0,
        query,
      }),
    });
  }, [navigate, location.pathname, params]);

  const sort = useMemo(() => ({
    field: params.orderBy,
    order: params.orderPath,
  }), [params.orderBy, params.orderPath]);

  const filters = useMemo(() => params.filters || EMPTY_FILTERS, [params.filters]);
  const query = useMemo(() => params.query || '', [params.query]);

  const pagination = useMemo(() => ({
    total,
    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
    showSizeChanger: true,
    current: Math.round((params.offset || 0) / (params.limit || 50) + 1),
    pageSize: parseInt(params.limit, 10) || 50,
  }), [params.offset, params.limit, total]);
  // orderBy=digi_price&orderPath=ascend

  return {
    error,
    list,
    isLoading,
    pagination,
    onChange,
    sort,
    filters,
    onSearch,
    query,
    fetch,
    onFilter,
    archive,
    data
  };
}
