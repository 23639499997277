import { useEffect, useState } from 'react';

export default function(key, defaultValue) {
    let prevValue = defaultValue;

    try {
        const storageValue = localStorage.getItem(key);
        if (storageValue) {
            prevValue = JSON.parse(storageValue);
        }
    } catch (e) {}

    const [value, setValue] = useState(prevValue);

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return [value, setValue];
}
