import React, {useEffect, useState} from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ErrorBlock from '../../Components/ErrorBlock';
import './styles.scss';
import {fetchConfigs} from '../../Reducers/config';
import {getPricingOffers} from "../../Reducers/pricingOffers";
import PricingOffersTable from "./PricingOffersTable";
import CreatePricingOffer from "./CreatePricingOffer";
import TrustedOffersTable from "./TrustedOffersTable";
import StatusButton from "./StatusButton";
import UpdateButton from "./UpdateButton";

const b = b_.lock('Accounts');

function PricingOffers() {
  const {
    pagination,
    onChange,
    isLoading,
    fetch,
    sort,
    error,
    onFilter,
    data
  } = useTableState(state => state.pricingOffers.pricingOffers, getPricingOffers);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  const [selectedRow, setSelectedRow] = useState([]);

  return <div className={b()}>
    <Title level={2} className="mb">
      Offers
    </Title>
    <div className={b('accountBlock')} style={{flexWrap: 'wrap'}}>
      <StatusButton className="mr" selected={selectedRow} afterSuccess={fetch} status={"Enabled"}/>
      <StatusButton className="mr" selected={selectedRow} afterSuccess={fetch} status={"Disabled"}/>
      <UpdateButton className="mr" afterSuccess={fetch} selected={selectedRow} />
      <CreatePricingOffer className="mr" afterSuccess={fetch}/>
    </div>

    {error && <ErrorBlock className="mb" message={error.message} description={error.description}/>}
    <PricingOffersTable selected={selectedRow}
                        setSelected={setSelectedRow}
                        afterUpdate={fetch}
                        pagination={pagination}
                        onChange={onChange}
                        isLoading={isLoading}
                        sorter={sort}
                        pricingOffers={data}
                        onFilter={onFilter}
    />
    <Title level={2} className="mb">
      Trusted Offers
    </Title>
    <TrustedOffersTable selected={selectedRow}
                        setSelected={setSelectedRow}
                        afterUpdate={fetch}
                        pagination={pagination}
                        onChange={onChange}
                        isLoading={isLoading}
                        sorter={sort}
                        pricingOffers={data}
                        onFilter={onFilter}
    />
  </div>;
}

export default PricingOffers;
