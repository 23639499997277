import React, {useState} from 'react'
import {Input, Popover} from "antd";
import {Button} from "@material-ui/core";
import './styles.scss'
import {EditTwoTone} from "@ant-design/icons";
import useBoolean from "../../Utils/useBoolean";
import PaxfulClientSelector from "../PaxfulClientSelector";

function UpdatePricingOffer({value, title, updatePricingOffers, rec, type}) {
  const [currValue, setValue] = useState(value)
  const {toggleValue: changeVisible, value: visible} = useBoolean(false)

  const handleSave = () => {
    const params = {
      id: rec.id,
      version: rec.version
    }
    changeVisible()
    params[title] = currValue
    updatePricingOffers(params)
  }

  return (
    <Popover
      title="Update"
      trigger="click"
      visible={visible}
      onVisibleChange={changeVisible}
      content={
        <div className="proxies_change">
          {type === 'text' &&
          <Input
            defaultValue={currValue}
            onChange={(e) => setValue(e.target.value)}
          />
          }
          {type === 'number' &&
          <Input type="number"
                 defaultValue={currValue}
                 onChange={(e) => setValue(e.target.value)}
          />
          }
          {type === 'rate' &&
          <Input type="number"
                 defaultValue={currValue}
                 onChange={(e) => setValue(e.target.value)}
          />
          }
          {type === 'paxful-client' &&
          <PaxfulClientSelector value={currValue} onChange={(v) => {
            setValue(v)
          }
          }/>
          }
          <div className={"ml updateProxiesButton"}>
            <Button
              color="default"
              variant="contained"
              onClick={changeVisible}
            >
              cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={currValue === value}
              style={currValue !== value ? {backgroundColor: "#1976d2"} : {}}
              onClick={handleSave}
            >
              save
            </Button>
          </div>
        </div>
      }
    >
      <Popover content={'Edit ' + title}>
        <div style={{marginRight: "10px"}}>
          {value}
          <EditTwoTone
            className={"anticon"}
            style={{
              position: 'absolute',
              top: '50%',
              left: 'calc(100% - 15px)',
              transform: 'translateY(-50%)',
            }}
          />
        </div>
      </Popover>
    </Popover>
  );
}

export default UpdatePricingOffer
