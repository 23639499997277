import React, { useCallback, useMemo, useState } from 'react';
import b_ from 'b_';
import {Drawer, Form, Input, message, Switch} from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { createPricingOffer } from '../../../Reducers/pricingOffers';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import {Button} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";
import PaxfulClientSelector from "../../../Components/PaxfulClientSelector";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

const b = b_.lock('CreatePricingOffer');

function CreatePricingOffer({ className, afterSuccess }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.pricingOffers.createPricingOffer.isLoading, shallowEqual);
  const [form] = Form.useForm();
  const [margin, setMargin] = useState(0);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const handleSuccess = useCallback((data) => {
    data.version = 0
    data.status = 'Disabled'
    data.cryptoCurrency = 'BTC'
    dispatch(createPricingOffer(data)).then(() => {
      close();
      afterSuccess();
      message.success('Offer has been added!');
    });
  }, [close, afterSuccess, dispatch]);

  const error = useSelector(state => state.pricingOffers.createPricingOffer.error, shallowEqual);
  const handleUpdateMargin = (e) => {
    const rateValue = e.target.value
    const newMargin = (((1 - (rateValue / 100)) / (rateValue / 100)) * 100).toFixed(2)
    setMargin(newMargin)
  }

  return (
    <>
      <Button
          color="primary"
          variant="contained"
          size="medium"
          className={className}
          startIcon={<PlusOutlined />}
          style={{ backgroundColor: "#1976d2"}}
          onClick={open}
      >
        Create
      </Button>
      <Drawer visible={isOpen} title="Create Account" width={600} closable placement="right" onClose={close}>
        {error && <ErrorBlock message={error.message} />}
        <Form layout="vertical" className={b()} onFinish={handleSuccess} form={form}>
          <Form.Item
            rules={[
              { required: true, message: 'Please input ID.' },
            ]}
            label="Hash:"
            name="hash"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: false, message: 'Please input Trusted ID.' },
            ]}
            label="Trusted Hash:"
            name="trustedHash"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input Payment Method.' },
            ]}
            label="Payment Method:"
            name="paymentMethod"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input Currency.' },
            ]}
            label="Currency:"
            name="currency"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Fixed Price:"
            name="fixedPrice"
          >
            <Switch
              size="small"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}/>
          </Form.Item>
          <Form.Item
              label="Rate:"
              name="rate"
              hasFeedback={form.getFieldValue('rate')}
              validateStatus="success"
          >
            <Input type="number" onChange={(e) => handleUpdateMargin(e)} />
          </Form.Item>

          <Input 
          addonBefore='Margin:' 
          addonAfter='%' 
          type="number" 
          disabled
          value={margin} 
          />
          {/* <Form.Item
            rules={[
              { required: true, message: 'Please input Min Margin.' },
            ]}
            label="Min Margin:"
            name="minMargin"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input Max Margin.' },
            ]}
            label="Max Margin:"
            name="maxMargin"
          >
            <Input type="number" />
          </Form.Item> */}
          <Form.Item
            label="Trusted Margin Fix:"
            name="trustedMarginFix"
          >
            <Input type="number"/>
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input Paxful Client.' },
            ]}
            label="Paxful Client:"
            name="paxfulClientName"
          >
            <PaxfulClientSelector/>
          </Form.Item>
          <Form.Item>
            <Button
                color="primary"
                variant="contained"
                startIcon={isLoading && <LoadingOutlined />}
                style={{ backgroundColor: "#1976d2"}}
                type="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreatePricingOffer;
