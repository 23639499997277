import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

function ErrorBlock({ description = '', message = 'Failed to load page data.', className = '' }) {

  return <Alert message={message}
                description={description}
                type="error"
                showIcon
                className={className}
  />;
}

ErrorBlock.propTypes = {
  description: PropTypes.string,
  message: PropTypes.string,
};

export default ErrorBlock;
