import React, {useEffect} from 'react';
import {Select} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {fetchAux} from "../../Reducers/aux";

function PaxfulClientSelector({className, value, onChange, mode}) {
  const aux = useSelector((state) => state.aux.data.payload, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!aux) {
      dispatch(fetchAux());
    }
  }, []);

  return (
    <Select
      mode={mode}
      className={className}
      value={value}
      onChange={onChange}
      style={{width: '20ch'}}>
      {aux && aux.paxfulClientNames.map(name => <Select.Option key={name} value={name}>{name}</Select.Option>)}
    </Select>);
}

export default PaxfulClientSelector;
