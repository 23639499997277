import React, { useCallback } from 'react';
import { Popconfirm, message } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { editPricingOffers } from '../../../Reducers/pricingOffers';
import {Button} from "@material-ui/core";

function StatusButton({ className, selected, afterSuccess, status }) {
  const { isLoading } = useSelector(state => state.pricingOffers.editPricingOffers, shallowEqual);
  const dispatch = useDispatch();

  const submit = useCallback(() => {
    dispatch(editPricingOffers({ status: status, ids: selected }))
      .then(() => {
      afterSuccess();
      message.success(`Offers has been ${status}!`);
    })
      .catch(() => {
        message.error(`Can't set ${status}!`);
      });
  }, [dispatch, selected, afterSuccess]);

  return (<Popconfirm title={`Are you sure to set ${status} ${selected.length} offers?`}
                      onConfirm={submit}
                      okText="Yes"
                      cancelText="No"
  >
    <Button
        color="primary"
        className={className}
        variant="contained"
        style={selected.length ? {backgroundColor: "#1976d2"} : {}}
        disabled={!selected.length}>
      {status.slice(0, -1)} {selected.length}
    </Button>
  </Popconfirm>);
}

export default StatusButton;
