import React, { useCallback, useMemo, useReducer, useState } from 'react';
import b_ from 'b_';
import { Checkbox, Drawer, Form, Input, message, Popconfirm } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { editPricingOffers } from '../../../Reducers/pricingOffers';
import EditFilled from '@ant-design/icons/lib/icons/EditFilled';
import frc from 'front-end-common';
import {Button} from "@material-ui/core";
import {LoadingOutlined} from "@ant-design/icons";

const b = b_.lock('CreateAccount');
const useBoolean = frc.hooks.useBoolean;

function UpdateButton({ className, afterSuccess, selected }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector(state => state.pricingOffers.editPricingOffers.isLoading, shallowEqual);
  const [margin, setMargin] = useState(0)

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const [resetFields, setResetFields] = useState([]);

  const changeResetFields = useCallback((field) => {
    if (resetFields.includes(field)) {
      return setResetFields(resetFields.filter(v => v !== field));
    }

    setResetFields([...resetFields, field]);
  }, [setResetFields, resetFields]);

  const handleSuccess = useCallback((formFields) => {
    const someExist = Object.keys(formFields).some(key => formFields[key]);

    if (!someExist && resetFields.length === 0) {
      return message.error('Please input one of field');
    }

    const fields = {...formFields, ...resetFields.reduce((acc, cur) => ({ ...acc, [cur]: '' }), {})};

    const data = { ids: selected, ...fields };
    dispatch(editPricingOffers(data)).then(() => {
      close();
      afterSuccess();
      message.success('Offers has been updated!');
      form.resetFields();
    });
  }, [close, afterSuccess, dispatch, selected, resetFields, form]);

  const error = useSelector(state => state.pricingOffers.editPricingOffers.error, shallowEqual);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const handleUpdateMargin = (e) => {
    const rateValue = e.target.value
    const newMargin = (((1 - (rateValue / 100)) / (rateValue / 100)) * 100).toFixed(2)
    setMargin(newMargin)
  }

  return (
    <>
      <Button color="primary"
              variant="contained"
              disabled={!selected.length}
              className={className}
              style={selected.length ?{ backgroundColor: "#1976d2"} : {}}
              startIcon={<EditFilled />}
              onClick={open}
      >
        Update {selected.length}
      </Button>
      <Drawer visible={isOpen}
              title={`Update Offers: ${selected.length}`}
              width={600}
              closable
              destroyOnClose
              placement="right"
              onClose={close}
              forceRender
      >
        {error && <ErrorBlock message={error.message} />}
        <Form onFieldsChange={forceUpdate} form={form} layout="vertical" className={b()} onFinish={handleSuccess}>
        <Form.Item
              label="Rate:"
              name="rate"
              hasFeedback={form.getFieldValue('rate')}
              validateStatus="success"
          >
            <Input type="number" onChange={(e) => handleUpdateMargin(e)} />
          </Form.Item>
          <Input 
          addonBefore='Margin:' 
          addonAfter='%' 
          type="number" 
          disabled 
          value={margin} 
          style={{marginBottom: '24px'}}
          />
          {/* <Form.Item
              label="Min Margin:"
              name="minMargin"
              hasFeedback={form.getFieldValue('minMargin')}
              validateStatus="success"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
              label="Max Margin:"
              name="maxMargin"
              hasFeedback={form.getFieldValue('maxMargin')}
              validateStatus="success"
          >
            <Input type="number" />
          </Form.Item> */}
          <Form.Item>
            <Popconfirm
              title={`Are you sure update ${selected.length} offers?`}
              onConfirm={form.submit}
              okText="Yes"
              cancelText="No"
            >
              <Button
                  startIcon={isLoading && <LoadingOutlined/>}
                  style={{ backgroundColor: "#1976d2"}}
                  color="primary"
                  variant="contained">
                Submit
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default UpdateButton;
