import React from 'react';
import { useRoutes } from "react-router-dom";
import RedirectUserToMainPage from '../Components/RedirectUserToMainPage';
import RedirectGuestToSignInPage from '../Components/RedirectGuestToSignInPage';
import RedirectPage from '../Pages/RedirectPage';
import ProfileOutlined from '@ant-design/icons/lib/icons/ProfileOutlined';
import Config from '../Pages/Config';
import PricingOffers from "../Pages/PricingOffers";
import {LineChartOutlined} from "@ant-design/icons";

function Routes() {
  const element = useRoutes([
      {
        path: '/sign-in',
        exact: true,
        element: <RedirectUserToMainPage />,
      }, {
        path: '/redirect',
        exact: true,
        element: <RedirectPage />,
      },
      {
        path: '/',
        element: <RedirectGuestToSignInPage />,
        children: [
          {
            path: '/',
            exact: true,
            element: <PricingOffers />,
          }, {
            path: '/config',
            exact: true,
            element: <Config />,
          },
        ],
      },
    ]);
  return element;
}

export const links = [
  {
    href: '/',
    icon: <LineChartOutlined/>,
    title: 'Offers',
  }, {
    href: '/config',
    icon: <ProfileOutlined/>,
    title: 'Config',
  },
];

export default Routes;
